export default {
  objTypeFromId(id) {
    return id.split('::')[0];
  },
  objIdWithoutType(id, prefix) {
    const parts = id.split(prefix);
    return parts[parts.length - 1];
  },
  alphabeticallySortedArrayFromObj(obj) {
    return Object.keys(obj).sort();
  }
};
