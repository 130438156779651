import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/store';
import authorization from '../auth/authorization';
import session from '../auth/session';
import { useToast } from 'vue-toastification';
import i18n from '../i18n/i18n';
import DevUtils from '../views/DevUtils';
import Sync from '../views/Sync';
import SignIn from '../views/SignIn';
import CreateProductionOrder from '../views/CreateProductionOrder';
import ProductionOrderList from '../views/ProductionOrderList';
import ProductList from '../views/ProductList';
import ChecklistList from '../views/ChecklistList';
import ChecklistForm from '../views/ChecklistForm';
import QualityInspection from '../views/QualityInspection';
import QualityInspectionList from '../views/QualityInspectionList';
import ShowQualityInspection from '../views/ShowQualityInspection';
import WriteProductIdToNfc from '../views/WriteProductIdToNfc';

const toast = useToast();
const rootPath = '/productionOrders';
const routes = [
  {
    path: '/',
    redirect: rootPath,
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/devUtils',
    name: 'DevUtils',
    component: DevUtils,
  },
  {
    path: '/sync',
    name: 'Sync',
    component: Sync,
    props: (route) => ({
      newVersionInstalled:
        route.query['newVersionInstalled'] === 'true' ? true : false,
      signingOut: route.query['signingOut'] === 'true' ? true : false,
    }),
  },
  {
    path: '/createProductionOrder',
    name: 'CreateProductionOrder',
    component: CreateProductionOrder,
  },
  {
    path: '/productionOrders',
    name: 'ProductionOrderList',
    component: ProductionOrderList,
  },
  {
    path: '/productionOrders/:productionOrderId/products',
    name: 'ProductList',
    component: ProductList,
    props: true,
  },
  {
    path: '/checklists',
    name: 'ChecklistList',
    component: ChecklistList
  },
  {
    path: '/checklists/new',
    name: 'CreateChecklist',
    component: ChecklistForm
  },
  {
    path: '/checklists/:checklistId/edit',
    name: 'EditChecklist',
    component: ChecklistForm,
    props: true,
  },
  {
    path: '/checklists/:checklistId/clone',
    name: 'CloneChecklist',
    component: ChecklistForm,
    props: true,
  },
  {
    path: '/products/:productId/qualityInspection',
    name: 'QualityInspection',
    component: QualityInspection,
    props: true,
  },
  {
    path: '/products/:productId/qualityInspections',
    name: 'QualityInspectionList',
    component: QualityInspectionList,
    props: true,
  },
  {
    path: '/products/:productId/writeToNfc',
    name: 'WriteProductIdToNfc',
    component: WriteProductIdToNfc,
    props: true,
  },
  {
    path: '/qualityInspections/:qualityInspectionId',
    name: 'ShowQualityInspection',
    component: ShowQualityInspection,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  return session
    .loadIntoStore()
    .then(() => {
      if (to.name === 'SignIn') return '/';
      if (authorization.isAuthorized(store.state.user, to.name)) return true;

      toast.warning(i18n.translate('actions.authUnauthorizedError'));
      return '/';
    })
    .catch(() => {
      const authNotRequired = ['SignIn'];
      if (authNotRequired.includes(to.name)) return true;

      // We choose not to display a not signed in message if the user
      // was trying to access the root path. Otherwise, users would
      // always see a not signed in error when first accessing the app.
      if (to.path !== rootPath)
        toast.warning(i18n.translate('actions.authNotSignedInError'));
      return { name: 'SignIn' };
    });
});

router.afterEach((to, from) => {
  const toggler = document.querySelector('.navbar-toggler[aria-expanded=true]');
  if (toggler) toggler.click();

  window.scroll({ top: 0, left: 0, behavior: 'instant' });
});

export default router;
