<template>
  <FullscreenModal :isActive="showConfirmDelete" @modalCompleted="handleDeleteItemModal">
    <template v-slot:body>
      {{ i18n.t('views.upsertChecklist.deleteMainText') }}
    </template>
    <template v-slot:confirmBtn>
      {{ i18n.t('views.upsertChecklist.deleteConfirmAction') }}
    </template>
    <template v-slot:cancelBtn>
      {{ i18n.t('views.upsertChecklist.deleteCancelAction') }}
    </template>
  </FullscreenModal>

  <FullscreenModal :isActive="showDoubleCheckCancel" @modalCompleted="handleDoubleCheckCancelModal">
    <template v-slot:body>
      {{ i18n.t('actions.doubleCheckCancelMainText') }}
    </template>
    <template v-slot:confirmBtn>
      {{ i18n.t('actions.doubleCheckProceedCancel') }}
    </template>
    <template v-slot:cancelBtn>
      {{ i18n.t('actions.doubleCheckAbandonCancel') }}
    </template>
  </FullscreenModal>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3" v-show="!showItemForm">{{ title }}</h1>
        <div v-if="!fetchingError">
          <form novalidate class="my-4" @input="autosave" @submit.prevent v-show="!showItemForm">
            <label for="cl-id-input" class="form-label">ID</label>
            <input type="text" id="cl-id-input" class="form-control" :disabled="currentRouteName === 'EditChecklist'"
              v-model="clId" />
            <div class="text-danger" v-html="clIdError"></div>

            <label for="cl-display-name-input" class="form-label mt-3">Nome de exibição</label>
            <input type="text" id="cl-display-name-input" class="form-control" v-model="displayName" />
            <div class="text-danger">{{ displayNameError }}</div>

            <div v-if="sortedSectionNames.length > 0">
              <div class="p-3 mt-5 border" v-for="sectionName in sortedSectionNames" :key="sectionName">
                <div class="d-flex justify-content-between">
                  <h3>{{ sectionName }}</h3>
                  <button type="button" class="ms-3 btn btn-outline-success" @click="addItem(sectionName)">
                    <i class="bi bi-plus-circle me-1"></i>
                    Novo item
                  </button>
                </div>
                <div class="mt-3" v-for="(item, key) in sections[sectionName]" :key="item.order">
                  <div class="card mnrt-card mb-2">
                    <div class="card-body">
                      <p>{{ item.text }}</p>
                      <button class="card-link btn btn-outline-dark" @click="editItem(key)">
                        <i class="bi bi-pencil"></i>
                      </button>
                      <button class="card-link btn btn-outline-danger" @click="deleteItem(key)">
                        <i class="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mt-5 alert alert-info">
              <i class="bi bi-info-square-fill me-2"></i>
              <span>Toda checklist deve ter, ao menos, uma seção e um item.</span>
            </div>

            <hr class="mt-5" />

            <form novalidate class="row row-cols-sm-auto align-items-center g-2 my-5" @submit.prevent>
              <div class="col-12">
                <input class="form-control" type="text" v-model.trim="newSectionName" />
              </div>
              <div class="col-12">
                <button :disabled="newSectionName.length === 0" type="button" class="ms-3 btn btn-outline-success"
                  @click="addSection">
                  <i class="bi bi-plus-circle me-1"></i>
                  Nova seção
                </button>
              </div>
            </form>

            <hr />

            <div class="my-5">
              <button type="button" class="btn btn-success" :disabled="sortedSectionNames.length === 0"
                @click="saveChecklist">
                {{ i18n.t('actions.save') }}
              </button>
              <button type="button" class="ms-3 btn btn-outline-danger" @click="cancelChanges">
                {{ i18n.t('actions.cancel') }}
              </button>
              <div class="mt-3 text-danger">
                {{ checklistError }}
              </div>
            </div>
          </form>

          <ChecklistItemForm v-if="currentItem" :isActive="showItemForm" :checklistSections="sectionsCollection"
            :checklistItemCategories="clItemCategories" :unitsOfMeasurement="unitsOfMeasurement"
            :checklistItemId="currentItemId" :checklistItem="currentItem"
            @checklistItemFormCompleted="checklistItemFormCompleted"
            @checklistItemFormCanceled="checklistItemFormCanceled" />
        </div>
        <p v-else>
          Não foi possível encontrar o checklist especificado ({{
            props.checklistId
          }}).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  toValue,
  toRaw,
  toRefs,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/i18n/i18n';
import store from '@/store/store';
import checklistDao from '@/dao/checklist_dao';
import FullscreenModal from '@/components/FullscreenModal';
import ChecklistItemForm from '@/components/ChecklistItemForm';
import { validatesPresence } from '@/validators/ui_validators';
import { checklistIdRegex } from '@/validators/regex';
import AppHelper from '@/helpers/app_helper';
import ChecklistHelper from '@/helpers/checklist_helper';
import { useToast } from 'vue-toastification';

export default {
  name: 'ChecklistForm',
  components: {
    FullscreenModal,
    ChecklistItemForm,
  },
  props: {
    checklistId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const toast = useToast();

    const model = reactive({
      currentRouteName: '',
      clItemCategories: [{ text: 'Selecione a categoria do item', value: '' }],
      unitsOfMeasurement: [],
      dbCl: null,
      clId: '',
      displayName: '',
      newSectionName: '',
      items: {},
      currentItemId: null,
      currentItem: null,
      showConfirmDelete: false,
      showDoubleCheckCancel: false,
      showItemForm: false,
      clIdError: '',
      displayNameError: '',
      checklistError: '',
      fetchingError: false,
    });

    const title = computed(() => {
      return model.currentRouteName === 'EditChecklist'
        ? 'Editando um checklist'
        : 'Novo checklist';
    });

    const sections = computed(() => {
      return ChecklistHelper.checklistSections(model.items);
    });

    const sortedSectionNames = computed(() => {
      return AppHelper.alphabeticallySortedArrayFromObj(toValue(sections));
    });

    const sectionsCollection = computed(() => {
      return Object.keys(toValue(sections)).map((sectionName) => ({
        text: sectionName,
        value: sectionName,
      }));
    });

    const dbId = () => {
      return model.clId ? `checklist::${model.clId}` : '';
    };

    const addSection = () => {
      // Adding a new section means adding a new item belonging to the section to be created
      model.items[nextItemId()] = newItem({
        type: 'geral',
        section: model.newSectionName,
        text: `Passou nas inspeções de ${model.newSectionName}`,
      });
      model.newSectionName = '';
    };

    const nextItemId = () => {
      if (Object.keys(model.items).length === 0) return '0';

      const sortedIds = Object.keys(model.items).sort((a, b) => a - b);
      return String(Number(sortedIds[sortedIds.length - 1]) + 1);
    };

    const nextItemOrder = () => {
      if (Object.keys(model.items).length === 0) return '0';

      const sortedPositions = Object.values(model.items)
        .map((item) => item.order)
        .sort((a, b) => a - b);
      return String(Number(sortedPositions[sortedPositions.length - 1]) + 1);
    };

    const newItem = (attrs = {}) => {
      return Object.assign(
        {
          type: '',
          section: '',
          text: '',
          help: '',
          order: nextItemOrder(),
          measurement: null,
        },
        attrs
      );
    };

    const addItem = (section) => {
      const item = newItem({
        section: section,
        order: nextItemOrder(),
        measurement: null,
      });
      model.currentItemId = null;
      model.currentItem = item;

      showItemForm(true);
    };

    const editItem = (itemId) => {
      model.currentItemId = itemId;
      model.currentItem = model.items[itemId];

      showItemForm(true);
    };

    const deleteItem = (itemId) => {
      model.currentItemId = itemId;
      model.currentItem = model.items[itemId];

      model.showConfirmDelete = true;
    };

    const handleDeleteItemModal = (confirmed) => {
      if (confirmed) {
        delete model.items[model.currentItemId];
        model.currentItemId = null;
        model.currentItem = null;
      }

      model.showConfirmDelete = false;
    };

    const checklistItemFormCompleted = (checklistItemId, checklistItem) => {
      if (checklistItemId) {
        model.items[checklistItemId] = checklistItem;
      } else {
        // New item
        model.items[nextItemId()] = checklistItem;
      }

      showItemForm(false);
      model.currentItemId = null;
      model.currentItem = null;
    };

    const checklistItemFormCanceled = () => {
      showItemForm(false);
      model.currentItemId = null;
      model.currentItem = null;
    };

    const showItemForm = (isVisible) => {
      window.scroll({ top: 0, left: 0, behavior: 'instant' });
      model.showItemForm = isVisible;
    };

    const validatesId = async () => {
      if (!model.clId) {
        model.clIdError = i18n.t('validations.shared.mustBeFilled');
        return false;
      }

      if (!checklistIdRegex.test(model.clId)) {
        model.clIdError = i18n.t('validations.checklist.id');
        return false;
      }

      // No need to check for a unique ID when editing a checklist
      if (model.currentRouteName !== 'EditChecklist') {
        const uniqueId = await checklistDao.isUniqueId(dbId());
        if (!uniqueId) {
          model.clIdError = i18n.t('validations.shared.mustBeUniqueId');
          return false;
        }
      }

      model.clIdError = '';
      return true;
    };

    const isChecklistValid = async () => {
      validatesPresence(model, 'displayName', 'displayNameError');
      await validatesId(); // TODO Validate against remote DB as well?

      const rawModel = toRaw(model);
      for (const attr in rawModel) {
        // Checking all error messages, returning false if any error is found.
        if (attr.toLowerCase().endsWith('error') && rawModel[attr])
          return false;
      }

      return true;
    };

    const saveChecklist = async () => {
      model.checklistError = '';

      const valid = await isChecklistValid();
      if (!valid) {
        model.checklistError = 'Corrija os campos com erros e tente novamente.';
        return false;
      }

      store.commit('activateFullscreenOverlay');

      if (model.currentRouteName === 'EditChecklist') {
        const cl = toValue(model.dbCl);
        cl.displayName = toValue(model.displayName);
        cl.items = toValue(model.items);

        await checklistDao.updateChecklist(cl);
      } else {
        const cl = {
          _id: dbId(),
          displayName: toValue(model.displayName),
          items: toValue(model.items),
        };

        await checklistDao.createChecklist(cl);
      }

      store.commit('deactivateFullscreenOverlay');
      router.push({ name: 'ChecklistList' });
      toast.success(i18n.t('actions.saved'));
    };

    const cancelChanges = () => {
      model.showDoubleCheckCancel = true;
    };

    const handleDoubleCheckCancelModal = (confirmed) => {
      model.showDoubleCheckCancel = false;

      if (confirmed) {
        router.push({ name: 'ChecklistList' });
      }
    };

    onMounted(async () => {
      store.commit('activateFullscreenOverlay');
      model.currentRouteName = useRoute().name;

      const clItemCategories = await checklistDao.getObjById(
        'collection::checklistItemCategories'
      );
      model.clItemCategories.push(...clItemCategories.textAndValues);
      const unitsOfMeasurement = await checklistDao.getObjById(
        'collection::unitsOfMeasurement'
      );
      model.unitsOfMeasurement.push(...unitsOfMeasurement.textAndValues);

      if (
        model.currentRouteName === 'EditChecklist' ||
        model.currentRouteName === 'CloneChecklist'
      ) {
        model.clId = toValue(props.checklistId);
        model.dbCl = await checklistDao.getObjById(dbId());
        if (model.dbCl) {
          model.displayName = model.dbCl.displayName;
          model.items = model.dbCl.items;
          if (model.currentRouteName === 'CloneChecklist') {
            model.clId = '';
          }
        } else {
          model.fetchingError = true;
        }
      }

      store.commit('deactivateFullscreenOverlay');
    });

    return {
      i18n,
      props,
      ...toRefs(model),
      title,
      sections,
      sortedSectionNames,
      sectionsCollection,
      addSection,
      addItem,
      editItem,
      deleteItem,
      handleDeleteItemModal,
      handleDoubleCheckCancelModal,
      checklistItemFormCompleted,
      checklistItemFormCanceled,
      saveChecklist,
      cancelChanges,
    };
  },
};
</script>
