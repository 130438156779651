<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-3">Checklists</h1>
        <router-link :to="{ name: 'CreateChecklist' }" class="mb-3 btn btn-outline-success">
          Criar novo checklist
        </router-link>
      </div>
    </div>
    <div v-if="checklists.length > 0">
      <ChecklistCard v-for="checklist in checklists" :key="checklist._id" :checklist="checklist" />
    </div>
    <div v-else class="row justify-content-center">
      <div class="col col-md-6">
        <p>Nenhuma checklist encontrada. Já fez a sincronização com o servidor?</p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import store from '@/store/store';
import checklistDao from '@/dao/checklist_dao';
import ChecklistCard from '@/components/ChecklistCard';

export default {
  name: 'ChecklistList',
  components: {
    ChecklistCard
  },
  setup() {
    let model = reactive({
      checklists: []
    });

    onMounted(async () => {
      store.commit('activateFullscreenOverlay');

      model.checklists = await checklistDao.getObjsByKeyPrefix('checklist::');

      store.commit('deactivateFullscreenOverlay');
    });

    return { ...toRefs(model) };
  }
}
</script>
