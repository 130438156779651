<template>
  <div v-if="loaded" class="container-fluid" style="min-height: 800px">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-3">Inspeção de qualidade</h1>
        <router-link :to="{
          name: 'QualityInspectionList',
          params: { productId: product._id },
        }" class="mb-3 btn btn-outline-dark">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </router-link>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.qualityInspection.passed') + ': '
          }}</span>
          <span :class="hasPassedInspectionClasses" class="d-inline-block">{{
            hasPassedInspection
          }}</span>
        </div>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.product.qaIdentifier') + ': '
          }}</span>
          <span class="d-inline-block">{{ product.qaIdentifier }}</span>
        </div>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.product.mineratecIdentifier') + ': '
          }}</span>
          <span class="d-inline-block">{{ product.mineratecIdentifier }}</span>
        </div>

        <div class="mb-2">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.qualityInspection.inspectorFullName') + ': '
          }}</span>
          <span class="d-inline-block">{{ qi.inspectorFullName }}</span>
        </div>

        <div class="mb-2">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.shared.observations') + ': '
          }}</span>
          <span class="d-inline-block">{{
            appViewHelper.format(qi.observations)
          }}</span>
        </div>

        <div class="mb-2" v-for="(
            labelAndValue, index
          ) in appViewHelper.printRecordDefaultFields(qi)" :key="index">
          <span style="width: 200px" class="d-inline-block">{{
            labelAndValue[0] + ': '
          }}</span>
          <span class="d-inline-block">{{ labelAndValue[1] }}</span>
        </div>

        <div class="mt-5">
          <h3 class="text-success">Itens aprovados</h3>
          <div v-if="anyPassedItem">
            <div v-for="(
                sectionItems, section, index
              ) in passedChecklistItemsGroupedBySection" :key="`passed_item_sections_${index}`">
              <label class="form-label mt-4 text-uppercase d-block">{{
                section
              }}</label>
              <ul class="d-block list-unstyled">
                <li class="mb-3" v-for="(item, index) in sectionItems" :key="`passed_item_${index}`">
                  <i class="text-success bi bi-check2"></i>
                  {{ item.text }}
                </li>
              </ul>
            </div>
          </div>
          <p v-else>Nenhum item de inspeção foi aprovado.</p>
        </div>

        <div class="mt-5">
          <h3 class="text-danger">Itens reprovados</h3>
          <div v-if="anyFailedItem">
            <div v-for="(
                sectionItems, section, index
              ) in failedChecklistItemsGroupedBySection" :key="`failed_item_sections_${index}`">
              <label class="form-label mt-4 text-uppercase d-block">{{
                section
              }}</label>
              <ul class="d-block list-unstyled">
                <li class="mb-3" v-for="(item, index) in sectionItems" :key="`failed_item_${index}`">
                  <i class="text-danger bi bi-x-octagon"></i>
                  {{ item.text }}<br>
                  <span v-if="item.measurement">
                    (Medido: {{ checklistItemMeasurementTakenText(qi.checklistMeasurements[item.value].measurementTaken,
                      item) }}
                    / Esperado: {{ checklistItemExpectedMeasurementText(item) }})
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <p v-else>Nenhum item de inspeção foi reprovado.</p>
        </div>

        <div class="my-5">
          <h3>Fotos da inspeção</h3>
          <CouchDbImageGallery v-if="attachmentCollectionId" title="" :attachmentCollectionId="attachmentCollectionId"
            :database="remoteDb" />
          <p v-else>
            Essa inspeção não possui fotos, elas ainda não foram sincronizadas
            ou a conexão com o servidor falhou.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import ChecklistHelper from '../helpers/checklist_helper';
import { checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText } from '../helpers/checklist_item_helper';
import appViewHelper from '../view_helpers/app_view_helper';
import qualityInspectionDao from '../dao/quality_inspection_dao';
import attachmentDao from '../dao/attachment_dao';
import checklistDao from '../dao/checklist_dao';
import { RemoteDb, buildRemoteDbAddr } from '../db/db';
import CouchDbImageGallery from '../components/CouchDbImageGallery';
import store from '../store/store';

export default {
  name: 'ShowQualityInspection',
  components: {
    CouchDbImageGallery,
  },
  props: {
    qualityInspectionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { i18n, appViewHelper, checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText };
  },
  created() {
    store.commit('activateFullscreenOverlay');

    qualityInspectionDao.getObjById(this.qualityInspectionId, (qi) => {
      this.qi = qi;
      this.checklist = this.qi.checklist;

      qualityInspectionDao.getObjById(this.qi.productId, (product) => {
        this.product = product;

        this.loaded = true;
        store.commit('deactivateFullscreenOverlay');
      });
    });

    buildRemoteDbAddr('workspaceAttachments', (remoteDbAddr) => {
      this.remoteDb = new RemoteDb(remoteDbAddr);
      attachmentDao.getAttachmentsForObj(
        this.remoteDb,
        [this.qualityInspectionId],
        (attachmentCollections) => {
          if (attachmentCollections.length > 0)
            this.attachmentCollectionId = attachmentCollections[0]._id;
        }
      );
    });
  },
  data() {
    return {
      loaded: false,
      qi: null,
      product: null,
      checklist: null,
      attachmentCollectionId: null,
      remoteDb: null,
    };
  },
  computed: {
    hasPassedInspection() {
      return this.qi.passed ? 'Aprovado' : 'Reprovado';
    },
    hasPassedInspectionClasses() {
      return this.qi.passed ? 'text-success' : 'text-danger';
    },
    anyPassedItem() {
      return Object.keys(this.passedChecklistItemsGroupedBySection).length > 0;
    },
    anyFailedItem() {
      return Object.keys(this.failedChecklistItemsGroupedBySection).length > 0;
    },
    passedChecklistItemsGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.qi.passedChecklistItems
      );
    },
    failedChecklistItemsGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.qi.failedChecklistItems
      );
    },
  },
};
</script>
