<template>
  <div v-if="isActive">
    <a
      href="#"
      class="d-inline-block btn btn-outline-dark mt-3"
      @click="confirmChanges(false)"
    >
      <i class="d-inline-block me-2 bi bi-arrow-return-left"></i>
      <span>Voltar ao checklist</span>
    </a>
    <h3 class="mt-3">Item do checklist</h3>
    <form novalidate class="my-4">
      <label for="cl-item-section-select" class="form-label mt-3"
        ><b>Seção</b></label
      >
      <select id="cl-item-section-select" class="form-select" v-model="section">
        <option
          v-for="section in checklistSections"
          :key="section.value"
          :value="section.value"
        >
          {{ section.text }}
        </option>
      </select>
      <p class="mt-1 text-danger">{{ sectionError }}</p>

      <label for="cl-item-type-select" class="form-label mt-3"
        ><b>Categoria</b></label
      >
      <select id="cl-item-type-select" class="form-select" v-model="type">
        <option
          v-for="cat in checklistItemCategories"
          :key="cat.value"
          :value="cat.value"
        >
          {{ cat.text }}
        </option>
      </select>
      <p class="mt-1 text-danger">{{ typeError }}</p>

      <label for="cl-item-text-input" class="form-label mt-3"
        ><b>Texto</b></label
      >
      <textarea
        id="cl-item-text-input"
        class="form-control"
        rows="6"
        v-model.trim="text"
      />
      <p class="mt-1 text-danger">{{ textError }}</p>

      <label for="cl-item-help-input" class="form-label mt-3"
        ><b>Ajuda</b></label
      >
      <textarea
        id="cl-item-help-input"
        class="form-control"
        rows="6"
        v-model.trim="help"
      />

      <div class="mt-5 p-2 border">
        <h5>Medições</h5>

        <div class="mt-3 align-self-center form-check form-switch">
          <input
            id="cl-item-expects-measurement-checkbox"
            class="form-check-input rounded-pill"
            type="checkbox"
            role="switch"
            v-model="requiresMeasurement"
          />
          <label
            class="form-check-label"
            for="cl-item-expects-measurement-checkbox"
          >
            Esse item requer uma medição
          </label>
        </div>

        <div v-show="requiresMeasurement">
          <p class="mt-3">
            <i>
              Separar a parte decimal seguindo o padrão americano, ou seja,
              usando . (ponto) e não , (vírgula).<br />
              <span class="text-danger">Incorreto: 1,70</span><br />
              <span class="text-success">Correto: 1.70</span>
            </i>
          </p>

          <div class="mt-3 row g-2 align-items-center">
            <div class="col-auto">
              <label
                for="cl-item-measurement-unit-select"
                class="form-label"
                style="min-width: 192px"
              >
                <b>Unidade de medida</b>
              </label>
            </div>
            <div class="col-auto">
              <select
                id="cl-item-measurement-unit-select"
                class="form-select"
                v-model="unit"
              >
                <option
                  v-for="unit in unitsOfMeasurement"
                  :key="unit.value"
                  :value="unit.value"
                >
                  {{ unit.text }}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-3 row g-2 align-items-center">
            <div class="col-auto">
              <label
                for="cl-item-ideal-value-input"
                class="form-label"
                style="min-width: 192px"
              >
                <b>Medida ideal</b>
              </label>
            </div>
            <div class="col-auto">
              <input
                type="number"
                id="cl-item-ideal-value-input"
                class="form-control"
                v-model="idealValue"
              />
            </div>
            <div class="col-auto">
              <p class="mt-1 text-danger">{{ idealValueError }}</p>
            </div>
          </div>

          <div class="mt-3 row g-2 align-items-center">
            <div class="col-auto">
              <label
                for="cl-item-tolerance-upper-bound-input"
                class="form-label"
                style="min-width: 192px"
              >
                <b>Afastamento superior (+)</b>
              </label>
            </div>
            <div class="col-auto">
              <input
                type="number"
                id="cl-item-tolerance-upper-bound-input"
                class="form-control"
                v-model="toleranceUpperBound"
              />
            </div>
            <div class="col-auto">
              <p class="mt-1 text-danger">{{ toleranceUpperBoundError }}</p>
            </div>
          </div>

          <div class="mt-3 row g-2 align-items-center">
            <div class="col-auto">
              <label
                for="cl-item-tolerance-lower-bound-input"
                class="form-label"
                style="min-width: 192px"
              >
                <b>Afastamento inferior (-)</b>
              </label>
            </div>
            <div class="col-auto">
              <input
                type="number"
                id="cl-item-tolerance-lower-bound-input"
                class="form-control"
                v-model="toleranceLowerBound"
              />
            </div>
            <div class="col-auto">
              <p class="mt-1 text-danger" v-html="toleranceLowerBoundError"></p>
            </div>
          </div>
        </div>
      </div>

      <div class="my-5">
        <button
          type="button"
          @click="confirmChanges(true)"
          class="btn btn-success"
        >
          {{ i18n.t('actions.save') }}
        </button>
        <button
          type="button"
          @click="confirmChanges(false)"
          class="ms-3 btn btn-outline-danger"
        >
          {{ i18n.t('actions.cancel') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, reactive, toRefs, toRaw, watch } from 'vue';
import i18n from '@/i18n/i18n';
import {
  validatesPresence,
  validatesGreaterThan,
  validatesGreaterThanOrEqualTo,
} from '@/validators/ui_validators';

export default {
  name: 'ChecklistItemForm',
  emits: ['checklistItemFormCompleted', 'checklistItemFormCanceled'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    checklistSections: {
      type: Array,
      required: true,
    },
    checklistItemCategories: {
      type: Array,
      required: true,
    },
    unitsOfMeasurement: {
      type: Array,
      required: true,
    },
    checklistItemId: {
      type: String,
      required: false,
    },
    checklistItem: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const model = reactive({
      checklistItemCategories: props.checklistItemCategories,
      unitsOfMeasurement: props.unitsOfMeasurement,
      section: '',
      sectionError: '',
      order: '',
      type: '',
      typeError: '',
      text: '',
      textError: '',
      help: '',
      requiresMeasurement: false,
      unit: 'mm',
      idealValue: '',
      idealValueError: '',
      toleranceUpperBound: '',
      toleranceUpperBoundError: '',
      toleranceLowerBound: '',
      toleranceLowerBoundError: '',
    });

    const validatesToleranceLowerBoundInterval = () => {
      const idealValue = parseFloat(model.idealValue);
      const toleranceLowerBound = parseFloat(model.toleranceLowerBound);

      if (model.idealValueError) {
        // We should abort if idealValue is invalid
        return false;
      }

      if (
        !isNaN(idealValue) &&
        !isNaN(toleranceLowerBound) &&
        toleranceLowerBound >= 0 &&
        toleranceLowerBound < idealValue
      ) {
        model.toleranceLowerBoundError = '';
        return true;
      }

      model.toleranceLowerBoundError = i18n.t(
        'validations.checklistItem.toleranceLowerBound'
      );
      return false;
    };

    const cloneChecklistItemBaseAttrs = (origin, target = {}) => {
      target.section = origin.section;
      target.order = origin.order;
      target.text = origin.text;
      target.type = origin.type;
      target.help = origin.help;

      return target;
    };

    const cloneChecklistItemMeasurement = (ctx) => {
      if (ctx === 'fromProps') {
        model.requiresMeasurement = props.checklistItem['measurement'] != null;
        if (model.requiresMeasurement) {
          model.unit = props.checklistItem.measurement.unit;
          model.idealValue = props.checklistItem.measurement.idealValue;
          model.toleranceUpperBound =
            props.checklistItem.measurement.toleranceUpperBound;
          model.toleranceLowerBound =
            props.checklistItem.measurement.toleranceLowerBound;
        }

        return model;
      } else {
        // ctx === 'toEvent'
        let measurement = null;

        if (model.requiresMeasurement) {
          measurement = {};
          measurement.unit = model.unit;
          measurement.idealValue = model.idealValue;
          measurement.toleranceUpperBound = model.toleranceUpperBound;
          measurement.toleranceLowerBound = model.toleranceLowerBound;
        }

        return measurement;
      }
    };

    const isChecklistItemValid = () => {
      validatesPresence(model, 'section', 'sectionError', true);
      validatesPresence(model, 'type', 'typeError', true);
      validatesPresence(model, 'text', 'textError');

      if (model.requiresMeasurement) {
        validatesGreaterThan(model, 'idealValue', 'idealValueError', 0);
        validatesGreaterThanOrEqualTo(
          model,
          'toleranceUpperBound',
          'toleranceUpperBoundError',
          0
        );
        validatesToleranceLowerBoundInterval();
      }

      const rawModel = toRaw(model);
      for (const attr in rawModel) {
        // Checking all error messages, returning false if any error is found.
        if (attr.toLowerCase().endsWith('error') && rawModel[attr])
          return false;
      }

      return true;
    };

    const confirmChanges = (confirmed) => {
      if (confirmed) {
        if (!isChecklistItemValid()) return false;

        let checklistItem = cloneChecklistItemBaseAttrs(model);
        checklistItem.measurement = cloneChecklistItemMeasurement('toEvent');
        ctx.emit(
          'checklistItemFormCompleted',
          props.checklistItemId,
          checklistItem
        );
      } else {
        ctx.emit('checklistItemFormCanceled');
      }
    };

    watch(
      () => props.checklistItem,
      () => {
        cloneChecklistItemBaseAttrs(props.checklistItem, model);
        cloneChecklistItemMeasurement('fromProps');
      },
      { immediate: true }
    );

    return { i18n, ...toRefs(model), confirmChanges };
  },
};
</script>
