<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-4">Ordens de produção</h1>
        <div class="btn-group mb-4" role="group">
          <div class="me-3">
            <input type="radio" v-model="productionOrdersFilter" value="qualityPending" class="btn-check"
              name="quality-pending-opt" id="radio-quality-pending-opt" autocomplete="off" checked />
            <label class="btn btn-outline-danger" for="radio-quality-pending-opt"><i
                class="bi-patch-exclamation-fill"></i></label>
          </div>

          <div class="me-3">
            <input type="radio" v-model="productionOrdersFilter" value="tagPending" class="btn-check"
              name="tag-pending-opt" id="radio-tag-pending-opt" autocomplete="off" />
            <label class="btn btn-outline-warning" for="radio-tag-pending-opt"><i class="bi-tags-fill"></i></label>
          </div>

          <div class="me-3">
            <input type="radio" v-model="productionOrdersFilter" value="completed" class="btn-check"
              name="completed-opt" id="radio-completed-opt" autocomplete="off" />
            <label class="btn btn-outline-success" for="radio-completed-opt"><i class="bi-patch-check-fill"></i></label>
          </div>
        </div>
        <h5 v-if="!hasProductionOrders">
          {{ noProductionOrdersMsg }}
        </h5>
      </div>
    </div>
    <div v-show="hasProductionOrders" class="row justify-content-center">
      <div class="col col-md-6">
        <h6 class="mb-3">
          <i>Exibindo da ordem de produção mais recente para a mais antiga</i>
        </h6>
        <div v-show="showLoadAllProductionOrdersBtn">
          <h6 class="mb-3">
            <i>{{ `Mostrando ${productionOrdersLoadLimit} registros (mais ordens podem existir)` }}</i>
          </h6>
          <button class="mb-3 btn btn-outline-dark" @click="getProductionOrders">
            Carregar todas as ordens
          </button>
        </div>
      </div>
    </div>
    <ProductionOrderCard v-for="productionOrder in productionOrders" :key="productionOrder._id"
      :productionOrder="productionOrder" />
    <div v-show="showLoadAllProductionOrdersBtn" class="row justify-content-center">
      <div class="col col-md-6">
        <button class="mb-5 btn btn-outline-dark" @click="getProductionOrders">
          Carregar todas as ordens
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ProductionOrderCard from '../components/ProductionOrderCard';
import dao from '../dao/production_order_dao';
import store from '../store/store';

export default {
  name: 'ProductionOrderList',
  components: {
    ProductionOrderCard,
  },
  created() {
    this.getProductionOrders();
  },
  data() {
    return {
      productionOrdersFilter: 'qualityPending',
      productionOrders: [],
      productionOrdersLoadLimit: 25,
    };
  },
  methods: {
    getProductionOrders(opts = {}) {
      store.commit('activateFullscreenOverlay');

      dao.getProductionOrders(Object.assign({ filter: this.productionOrdersFilter }, opts), (productionOrders) => {
        this.productionOrders = productionOrders;
        store.commit('deactivateFullscreenOverlay');
      });
    },
  },
  computed: {
    hasProductionOrders() {
      return this.productionOrders.length > 0;
    },
    noProductionOrdersMsg() {
      switch (this.productionOrdersFilter) {
        case 'qualityPending':
          return 'Nenhuma ordem pendente de aprovação na qualidade.';
        case 'tagPending':
          return 'Nenhuma ordem pendente de gravação de tags.';
        default:
          return 'Ainda não há ordens de produção finalizadas.';
      }
    },
    showLoadAllProductionOrdersBtn() {
      return this.productionOrdersFilter === 'completed' && this.productionOrders.length === this.productionOrdersLoadLimit;
    }
  },
  watch: {
    productionOrdersFilter(val, oldVal) {
      if (val !== oldVal) {
        val === 'completed' ? this.getProductionOrders({ limit: this.productionOrdersLoadLimit }) : this.getProductionOrders();
      }
    },
  },
};
</script>
