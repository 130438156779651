<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card mnrt-card mb-4">
        <div class="card-header mnrt-card-header">
          <h3>{{ checklist.displayName }}</h3>
        </div>
        <div class="card-body">
          <h5 class="card-title">
            ID: {{ appHelper.objIdWithoutType(checklist._id, 'checklist::') }}
          </h5>
          <router-link :to="{
            name: 'EditChecklist',
            params: { checklistId: appHelper.objIdWithoutType(checklist._id, 'checklist::') },
          }" class="card-link btn btn-outline-dark">
            <i class="bi bi-pencil"></i>
          </router-link>
          <router-link :to="{
            name: 'CloneChecklist',
            params: { checklistId: appHelper.objIdWithoutType(checklist._id, 'checklist::') },
          }" class="card-link btn btn-outline-dark">
            <i class="bi bi-copy"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appHelper from '@/helpers/app_helper';

export default {
  name: 'ChecklistCard',
  props: {
    checklist: {
      type: Object,
      required: true
    }
  },
  setup(props) { return { ...props, appHelper } }
};
</script>
